/* General */
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 62.5%;
  min-height: -webkit-fill-available;
}
body {
  min-height: -webkit-fill-available;
  background-image: url(/src/images/stacked-waves-haikei-osp.png);
  background-size: 100vw 100%;
  background-repeat: no-repeat;
  background-color: var(--aqua-2);
}
::selection {
  background-color: #f2f2f2;
}
.container {
  display: flex;
  padding: 2rem 3rem;
}

/* Colors */
:root {
  /* Black */
  --black-0: #000000;
  /* White */
  --white-0: #ffffff;
  /* Blue */
  --blue-0: #0008c1;
  --blue-2: #2146c7;
  /* Aqua */
  --aqua-1: #54bab9;
  --aqua-2: #7cb5a8;
  --aqua-3: #9ed2c6;
  --aqua-4: #b3dbd1;
  --aqua-5: #bde0d7;
  /* Pink */
  --pink-1: #eb2f96;
  --pink-2: #f759ab;
  --pink-3: #ff85c0;
}

/* Dark Mode Colors */
/* .dark {
  color: #ffffff;
  --black-0: #ffffff;
  --white-0: #000000;
  --blue-0: #b8e8fc;
  --blue-2: #cdfcf6;
} */

/* Navbar */
.navbar {
  align-items: center;
}
.nav-menu {
  font-size: 2.8rem;
  font-weight: 240;
  flex-grow: 1;
  text-align: left;
}
.nav-icons {
  display: flex;
  gap: 1.4rem;
}
.nav-icons svg {
  fill: var(--pink-1);
  width: 2.4rem;
  height: 2.4rem;
  transition: all 0.3s;
}
.nav-icons svg:hover {
  fill: var(--pink-2);
}
/* Body */
.instructions {
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0 3rem;
}
.instructions a {
  text-decoration: none;
  color: var(--blue-0);
  transition: all 0.3s;
}
.instructions a:hover {
  color: var(--blue-2);
}
.home {
  justify-content: space-between;
  align-items: center;
}
.home p {
  font-size: 1.6rem;
  font-weight: 250;
}
.home input {
  border: 1px solid var(--black-0);
  border-radius: 15px;
  outline: none;
  font-size: 2.4rem;
  font-weight: 220;
  padding: 10px;
  margin-right: 2rem;
  background-color: var(--aqua-3);
  flex-grow: 1;
}
.home input::placeholder {
  color: #4f4a4a;
  font-weight: 200;
}
.total {
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 220;
  padding-top: 0;
  padding-bottom: 0;
}
.total span {
  color: red;
  font-weight: 300;
}
.filters {
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-copy {
  color: var(--black-0);
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px 10px;
  border: 0px black solid;
  border-radius: 30px;
  background-color: var(--pink-1);
  cursor: pointer;
  width: auto;
  transition: all 0.3s;
}
.btn-copy:hover {
  color: var(--white-0);
  background-color: var(--pink-2);
  outline: var(--white-0) 1px solid;
}
.btn-copy-copied {
  color: var(--white-0);
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px 10px;
  border: 0px black solid;
  border-radius: 30px;
  background-color: var(--pink-2);
  cursor: pointer;
  width: auto;
  transition: all 0.3s;
}
.btn-copy-copied:hover {
  background-color: var(--pink-3);
}
.btn-month {
  color: var(--black-0);
  font-size: 1.4rem;
  padding: 4px 15px;
  border: 1px var(--black-0) solid;
  border-radius: 6px;
  background-color: var(--aqua-4);
  box-shadow: 0 2px 0 rgb(0 0 0 / 3%);
  cursor: pointer;
  width: 125px;
  height: 27px;
  transition: all 0.3s;
}
.btn-month:hover {
  color: var(--blue-0);
  border: 1px var(--blue-0) solid;
  background-color: var(--aqua-5);
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Transactions Table */
.tx-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 8px var(--black-0);
}
.transactions {
  font-size: 1.2rem;
  overflow-x: auto;
}
.tx-table th {
  background-color: var(--aqua-1);
}
.tx-table td,
.tx-table th {
  border: 1px solid var(--black-0);
  padding: 8px;
}
.time {
  text-align: center;
}
.hash {
  word-break: break-all;
}
.hash a {
  color: var(--blue-0);
  text-decoration: underline;
  transition: all 0.3s;
}
.hash a:hover {
  text-decoration: none;
  color: var(--blue-2);
}
.th-empty {
  border-right: none !important;
}
.th-fee {
  border-left: none !important;
}
.th-gas {
  background-color: var(--pink-1) !important;
  font-weight: bold;
  text-align: left;
}

/* Media Queries */
@media (max-width: 480px) {
  /* General */
  .container {
    padding: 1rem 1rem;
  }
  /* Navbar */
  .nav-menu {
    font-size: 2rem;
  }
  .nav-icons {
    gap: 1.2rem;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
  .nav-icons svg {
    width: 2rem;
    height: 2rem;
  }
  /* Body */
  .home p {
    font-size: 1.2rem;
  }
  .total {
    font-size: 1.2rem;
  }
  /* Transactions Table */
  .transactions {
    font-size: 1.1rem;
  }
}

@media (max-width: 655px) {
  /* Body */
  .instructions {
    font-size: 1.1rem;
  }
  .home input {
    font-size: 1.4rem;
    margin-right: 1rem;
    padding: 8px;
  }
  .btn-copy {
    font-size: 1.2rem;
    padding: 8px;
  }
  .btn-copy-copied {
    font-size: 1.2rem;
    padding: 8px;
  }
  .btn-month {
    font-size: 1rem;
    width: 85px;
    padding: 4px 10px;
  }
}
@media (max-width: 655px) and (min-width: 480px) {
  /* General */
  .container {
    padding: 1rem 1.5rem;
  }
}
